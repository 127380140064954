/* src/pages/Tutorials.module.css */

.tutorialsContainer {
  width: 90%;
  max-width: 800px;
  margin: 40px auto;
  background-image: radial-gradient(circle, #3b0b0b, #1a1a1a);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.7);
  border-top: 1px solid rgba(255, 0, 0, 0.5);
  border-left: 1px solid rgba(255, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 25px;
  padding: 30px;
  color: #f2f2f2;
}

.heading {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.tutorialsList {
  list-style: none;
  padding: 0;
}

.tutorialItem {
  margin-bottom: 20px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}

.tutorialItem:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.tutorialTitle {
  margin: 0 0 10px 0;
  color: #ff4d4d;
  font-size: 20px;
}

.tutorialLink {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 16px;
}

.tutorialLink:hover {
  text-decoration: underline;
}

@media (max-width: 850px) {
  .tutorialsContainer {
    padding: 20px;
  }

  .heading {
    font-size: 28px;
  }
}

@media (max-width: 410px) {
  .tutorialsContainer {
    padding: 15px;
  }

  .heading {
    font-size: 24px;
  }

  .tutorialTitle {
    font-size: 18px;
  }

  .tutorialLink {
    font-size: 14px;
  }
}

.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #fff;
  padding: 2rem 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
}

.tutorialGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.tutorialCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tutorialCard:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 77, 77, 0.3);
}

.cardIcon {
  font-size: 2rem;
  color: #ff4d4d;
  margin-bottom: 1.5rem;
}

.category {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ff4d4d;
  margin-bottom: 0.5rem;
  display: block;
}

.cardContent h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.cardContent p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.watchButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  color: white;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  width: fit-content;
}

.watchButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.watchButton svg {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .container {
    padding-top: 1rem;
  }

  .header h1 {
    font-size: 2rem;
  }

  .tutorialCard {
    padding: 1.5rem;
  }

  .cardIcon {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.8rem;
  }

  .tutorialGrid {
    grid-template-columns: 1fr;
  }

  .cardContent h3 {
    font-size: 1.2rem;
  }
}
