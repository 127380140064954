@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* General settings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

body {
  padding-top: 80px; /* Adjust this value based on your navbar height */
}

/* Navbar container with breathing effect and enhanced dot animation */
.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(13, 13, 13, 0.95);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 0;
  background: linear-gradient(135deg, rgba(26, 26, 26, 0.95), rgba(40, 0, 0, 0.95));
  /* Remove or comment out existing background-image if necessary */
  /* background-image: radial-gradient(circle at 10% 20%, rgba(255, 0, 0, 0.3), transparent 30%),
                    radial-gradient(circle at 80% 80%, rgba(255, 0, 0, 0.4), transparent 30%),
                    radial-gradient(circle at 50% 50%, rgba(255, 0, 0, 0.5), transparent 30%); */
  border-bottom: 1px solid rgba(255, 0, 0, 0.7);  /* Reduced from 2px */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);  
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  /* height: 80px; */ /* Remove fixed height */
  animation: pulse 3s infinite;  /* Added pulse animation */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.scrolled {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
  background: rgba(8, 8, 8, 0.98);
}

.navbarContent {
  width: 100%;
  max-width: 1200px; /* Reduced from 1400px for better content density */
  margin: 0 auto;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6.5rem;
}

.navbar::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;  /* Reduced from 4px */
  background: rgba(255, 0, 0, 0.3);  /* Made more subtle */
  overflow: hidden;
  z-index: 1001;
}

.navbar::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;  /* Reduced from 4px */
  background: linear-gradient(to right, 
    transparent 0%, 
    rgba(255, 0, 0, 0.6) 50%, 
    transparent 100%
  );
  filter: blur(2px);  /* Reduced blur */
  animation: move-dot 3s ease-in-out infinite;  /* Slowed down animation */
}

/* Breathing animation for the navbar */
@keyframes breathing {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 6px 20px rgba(255, 0, 0, 0.5);
  }
  50% {
    transform: scale(1.005);
    box-shadow: 0 8px 25px rgba(255, 0, 0, 0.7);
  }
}

/* Dot animation keyframes */
@keyframes move-dot {
  0% {
    transform: translateX(-100%) scaleX(1.5);
  }
  50% {
    transform: translateX(0%) scaleX(1);
  }
  100% {
    transform: translateX(100%) scaleX(1.5);
  }
}

.menuButton {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1000;
  margin-left: auto; /* Change this to push it to the right */
  margin-right: 1rem; /* Add spacing from right edge */
}

.mobileMenu {
  display: none;
  z-index: 1000;
  background: rgba(11, 11, 11, 0.95); /* This ensures the background is always visible */
  backdrop-filter: blur(10px);
}

.desktopMenu {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem; /* Increased from 2rem */
}

.navbar::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;  /* Reduced from 4px */
  background: rgba(255, 0, 0, 0.3);  /* Made more subtle */
  overflow: hidden;
  z-index: 1001;
}

.navbar::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;  /* Reduced from 4px */
  background: linear-gradient(to right, 
    transparent 0%, 
    rgba(255, 0, 0, 0.6) 50%, 
    transparent 100%
  );
  filter: blur(2px);  /* Reduced blur */
  animation: move-dot 3s ease-in-out infinite;  /* Slowed down animation */
}

/* Breathing animation for the navbar */
@keyframes breathing {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 6px 20px rgba(255, 0, 0, 0.5);
  }
  50% {
    transform: scale(1.005);
    box-shadow: 0 8px 25px rgba(255, 0, 0, 0.7);
  }
}

/* Dot animation keyframes */
@keyframes move-dot {
  0% {
    transform: translateX(-100%) scaleX(1.5);
  }
  50% {
    transform: translateX(0%) scaleX(1);
  }
  100% {
    transform: translateX(100%) scaleX(1.5);
  }
}

/* Logo styling */
.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.5rem;
  padding: 0;
  margin-right: 5.5rem;
  margin-left: -13rem;
}

.logoText {
  font-size: 1.7rem;
  font-weight: 700;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logoAccent {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
}


/* Menu styling */
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0 15px;
}

.menu li a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

.menu li a svg {
  font-size: 1.1rem;
}

.menu li a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  transition: width 0.3s ease;
}

.menu li:hover a::after,
.menu li.active a::after {
  width: 100%;
}

.menu a {
  color: #f2f2f2; /* Light color for menu links */
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  position: relative;
}

.menu a:hover {
  color: #ff4d4d; /* Hover color for menu links */
}

.menu a::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 2px;
  width: 100%;
  background: linear-gradient(90deg, transparent, #ff0707, transparent);
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.menu a:hover::before {
  transform: scaleX(1); /* Underline effect on hover */
}

/* Button styling */
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0;
  margin-left: 4rem; /* Increased from 2rem */
  margin-right: -2rem; /* Added to move buttons right */
  position: relative;
}

.loginBtn, .signupBtn {
  padding: 0.4rem 1.5rem;
  border-radius: 8px;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.3s ease;
}

.loginBtn {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.signupBtn {
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  color: #fff;
}

.loginBtn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.signupBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.buttons button {
  outline: none;
  color: #f2f2f2;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px 28px;
  border: 1px solid rgba(255, 0, 0, 0.8); /* Red border for buttons */
  margin: 0 5px;
  cursor: pointer;
  background: linear-gradient(135deg, #ad2020, #660000); /* Dark gradient background */
  box-shadow: 0 4px 15px rgba(255, 0, 0, 0.3), inset 0 0 10px rgba(255, 0, 0, 0.6); /* Glowing red shadow */
  transition: all 0.3s ease, box-shadow 0.2s ease;
}

.buttons button:hover {
  background: linear-gradient(135deg, #ff0707, #b30000); /* More intense red on hover */
  box-shadow: 0 0 20px rgba(255, 7, 7, 0.9), inset 0 0 20px rgba(255, 0, 0, 0.8); /* Intense glowing effect */
  transform: translateY(-3px) scale(1.05); /* Hover scale effect */
}

/* More dropdown menu styling - updated */
.moreMenuItem {
  position: relative;
  padding: 0.5rem 0;
}

.moreMenuTrigger {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.moreMenuTrigger svg {
  transition: transform 0.3s ease;
}

.moreMenuItem:hover .moreMenuTrigger,
.moreMenuItem.active .moreMenuTrigger {
  color: #ff4d4d;
  background: rgba(255, 255, 255, 0.05);
}

.moreMenuItem:hover .moreMenuTrigger svg,
.moreMenuItem.active .moreMenuTrigger svg {
  transform: rotate(-180deg);
}

.moreMenuDropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background: linear-gradient(135deg, rgba(26, 26, 26, 0.98), rgba(40, 0, 0, 0.98));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 0, 0, 0.2);
  border-radius: 8px;
  min-width: 200px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s ease;
  pointer-events: none;
  list-style: none; /* Add this line to remove bullets */
}

.moreMenuItem:hover .moreMenuDropdown,
.moreMenuItem.active .moreMenuDropdown {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}

.moreMenuDropdown::before {
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  width: 10px;
  height: 10px;
  background: rgba(26, 26, 26, 0.98);
  transform: rotate(45deg);
  border-left: 1px solid rgba(255, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 0, 0, 0.2);
}

.moreMenuDropdown li {
  margin: 0;
  padding: 0;
  list-style-type: none; /* Add this line to ensure no bullets */
}

.moreMenuDropdown a {
  padding: 0.8rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #fff;
  transition: all 0.3s ease;
}

.moreMenuDropdown a:hover {
  background: rgba(255, 77, 77, 0.1);
  color: #ff4d4d;
  transform: translateX(5px);
}

.moreMenuDropdown a svg {
  font-size: 1.1rem;
}

/* Update responsive styles for More menu */
@media (min-width: 1025px) and (max-width: 1400px) {
  .moreMenuTrigger {
    font-size: 0.9rem;
  }
  
  .moreMenuDropdown {
    min-width: 180px;
  }
  
  .moreMenuDropdown a {
    padding: 0.7rem 0.9rem;
    font-size: 0.9rem;
  }
}

/* Responsive Design */
@media (max-width: 850px) {
  .navbar {
    flex-direction: column;
    padding: 15px;
  }

  .menu {
    margin-top: 15px;
    padding-left: 0px;
  }

  .menu li {
    margin: 0 10px;
  }

  .buttons {
    margin-top: 20px;
  }
}

@media (max-width: 500px) {
  .navbar {
    padding: 15px 20px;
  }

  .logo a {
    font-size: 24px;
  }

  .menu a {
    font-size: 16px;
  }

  .buttons button {
    font-size: 14px;
    padding: 5px 10px;
  }
}

@media (max-width: 1024px) {
  body {
    padding-top: 70px;
  }
  
  .navbar {
    grid-template-columns: auto auto;
    padding: 0.8rem 1.5rem; /* Increased horizontal padding */
    height: 75px; /* Slightly increased height */
  }

  .navbarContent {
    padding: 0.8rem 1rem;
    max-width: 100%;
    justify-content: space-between; /* Change to space-between */
  }

  .logo a {
    padding-right: 0;
    font-size: 24px;
  }

  .logo {
    margin-right: 0;
    order: 1; /* Change to 1 to move it to the left */
    margin-left: 1rem; /* Add left margin */
    margin-right: 0; /* Reset right margin */
  }

  .buttons {
    padding-left: 0;
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(11, 11, 11, 0.95); /* Darkened background */
    backdrop-filter: blur(10px);
    padding: 5rem 2rem 2rem;
    z-index: 1000;
    justify-content: flex-start;
    padding-top: 60px; /* Reduced from 80px */
    overflow-y: auto;
    min-height: 100vh; /* Ensure full height */
  }

  .mobileNav {
    list-style: none;
    padding: 0;
    margin: 0;
    background: transparent; /* Keep the nav items transparent */
    margin-top: 0.5rem; /* Reduced from 1rem */
  }

  .mobileNav li {
    margin: 0.3rem 0; /* Reduced from 1rem */
  }

  .mobileNav a {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 1rem 0.8rem; /* Reduced padding */
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }

  .mobileNav a:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .mobileButtons {
    margin-top: 0.5rem; /* Reduced from 1rem */
    display: flex;
    flex-direction: column;
    gap: 0.8rem; /* Reduced from 0.8rem */
  }

  .mobileButtons .loginBtn,
  .mobileButtons .signupBtn {
    text-align: center;
    padding: 0.7rem; /* Reduced from 1rem */
  }

  .menuButton {
    display: block;
    order: 2; /* Change to 2 to move it to the right */
  }

  .desktopMenu {
    display: none;
  }

  .logo {
    order: 1; /* Change to 1 */
    margin-top: -5px;
    margin-left: auto; /* Center the logo */
    margin-right: auto;
  }

  .mobileMenu .menu {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;  /* Reduced from 0.5rem */
  }

  .mobileMenu .buttons {
    margin-top: 1rem;  /* Reduced from 2rem */
    display: flex;
    flex-direction: column;
    gap: 0.8rem;  /* Reduced from 1rem */
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .mobileMenu .menu li a:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .hamburger {
    display: block;
    position: relative;
    z-index: 9999;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .menuContainer {
    position: fixed;
    left: -100%;
    top: 0;
    padding-top: 70px;  /* Reduced from 100px */
    flex-direction: column;
    background-color: rgba(11, 11, 11, 0.98);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100vh;
    text-align: center;
    transition: 0.3s;
    z-index: 9998;
    justify-content: flex-start;
  }

  .menuContainer.active {
    left: 0;
    overflow-y: auto;
  }

  .menu {
    flex-direction: column;
    padding: 10px 0;  /* Reduced from 20px */
    width: 100%;
  }

  .menu li {
    margin: 8px 0;  /* Reduced from 15px */
  }

  .mobileMenu .menu li {
    width: 100%;
  }

  .mobileMenu .menu a {
    display: block;
    width: 100%;
    padding: 4px 20px;
    text-align: center;
  }

  .menu a::before {
    bottom: 8px;
  }

  .buttons {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 300px;
  }

  .buttons button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  body {
    padding-top: 65px;
  }
  
  .logo {
    margin-left: 1rem; /* Adjust left margin */
    margin-right: 0; /* Reset right margin */
  }

  .navbarContent {
    padding: 0.1rem;
  }

  .logoText, .logoAccent {
    font-size: 1.5rem;
  }

  .navbar {
    padding: 0.6rem 1.2rem; /* Increased horizontal padding */
    height: 65px; /* Slightly increased height */
  }

  .buttons button {
    padding: 6px 20px; /* Adjust padding */
    font-size: 14px; /* Adjust font size */
  }

  .menuContainer {
    padding-top: 600px;
  }

  .logo a {
    font-size: 24px; /* Adjust font size */
  }

  .menu a {
    font-size: 16px; /* Adjust font size */
  }

  .navbar {
    padding: 0.6rem 1rem;
    height: 60px;
  }

  .logoText, .logoAccent {
    font-size: 1.2rem;
  }

  .menuButton {
    padding: 0.4rem;
  }

  .menuButton svg {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  body {
    padding-top: 60px;
  }
  
  .logo a {
    font-size: 20px;
    letter-spacing: 2px;
  }

  .navbar {
    padding: 0.5rem 1rem; /* Increased horizontal padding */
    height: 60px; /* Slightly increased height */
  }

  .buttons button {
    padding: 5px 15px;
    font-size: 14px;
  }

  .menu a {
    font-size: 16px;
  }

  .mobileMenu {
    padding: 3rem 1rem 1rem;
  }
}

/* Desktop Responsive Design */
@media (min-width: 1025px) and (max-width: 1400px) {
  .navbarContent {
    padding: 0.8rem;
    gap: 1rem;
  }

  .logo {
    margin-left: -8rem; /* Reduced from -12rem */
    margin-right: 3rem; /* Reduced from 5rem */
  }

  .logoText, .logoAccent {
    font-size: 1.5rem;
  }

  .menu {
    gap: 1rem;
  }

  .menu li {
    margin: 0 8px;
  }

  .menu li a {
    font-size: 0.9rem;
    gap: 0.3rem;
  }

  .menu li a svg {
    font-size: 1rem;
  }

  .buttons {
    margin-left: 3rem;
    margin-right: -1rem;
    gap: 0.8rem;
  }

  .loginBtn, .signupBtn {
    padding: 0.3rem 0.8rem;
    font-size: 0.9rem;
  }

  .moreMenuTrigger {
    font-size: 0.9rem;
  }
  
  .moreMenuDropdown {
    min-width: 160px;
  }
  
  .moreMenuDropdown a {
    font-size: 0.85rem;
    padding: 0.6rem 0.8rem;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .desktopMenu {
    gap: 2rem;
  }

  .buttons {
    margin-left: 2rem;
    margin-right: -0.5rem;
  }

  .logo {
    margin-left: -4rem;
    margin-right: 2rem;
  }

  .logoText, .logoAccent {
    font-size: 1.4rem;
  }

  .menu li a {
    font-size: 0.85rem;
  }

  .menu {
    gap: 0.8rem;
  }

  .menu li {
    margin: 0 5px;
  }

  .buttons {
    margin-left: 0.8rem;
    gap: 0.6rem;
  }

  .loginBtn, .signupBtn {
    padding: 0.3rem 0.7rem;
    font-size: 0.85rem;
  }
}

/* Ensure desktop menu stays visible at these breakpoints */
@media (min-width: 1025px) {
  .desktopMenu {
    display: flex !important;
  }

  .menuButton {
    display: none !important;
  }

  .mobileMenu {
    display: none !important;
  }
}

/* Updated breathing animation with smoother transitions */
@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(255, 0, 0, 0.3);
  }
  50% {
    box-shadow: 0 4px 25px rgba(255, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 4px 15px rgba(255, 0, 0, 0.3);
  }
}


