.footer {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
  padding: 4rem 2rem 1rem 2rem;
  margin-top: 4rem;
  border-top: 1px solid rgba(255, 77, 77, 0.2);
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  margin-bottom: 3rem;
}

.footerSection h3 {
  color: #ff4d4d;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.footerSection p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  font-size: 0.95rem;
}

.footerSection ul {
  list-style: none;
  padding: 0;
}

.footerSection ul li {
  margin-bottom: 0.8rem;
}

.footerSection ul li a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.95rem;
}

.footerSection ul li a:hover {
  color: #ff4d4d;
}

.footerBottom {
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footerBottom p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
}

.telegramLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.telegramLink:hover {
  color: #ff4d4d;
}

.telegramLink svg {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 3rem 1rem 1rem 1rem;
  }

  .footerContent {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footerSection {
    text-align: center;
  }

  .footerSection ul li {
    margin-bottom: 0.6rem;
  }
}
