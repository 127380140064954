.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, rgba(14, 14, 14, 0.97) 0%, rgba(28, 28, 28, 0.97) 100%);
  backdrop-filter: blur(10px);
  z-index: 9999;
  transition: opacity 0.3s ease-out;
}

.loader {
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  mix-blend-mode: overlay;
}

.loader-ring:nth-child(1) {
  border-top-color: #4ff0ff;
  animation: rotate1 2s linear infinite;
}

.loader-ring:nth-child(2) {
  border-right-color: #f657ff;
  animation: rotate2 2s linear infinite;
}

.loader-ring:nth-child(3) {
  border-bottom-color: #ffcc1f;
  animation: rotate3 2s linear infinite;
}

.loader-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  animation: pulse 1s ease-in-out infinite;
}

.loading-text {
  margin-top: 30px;
  display: flex;
  gap: 2px;
}

.loading-text span {
  color: white;
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  opacity: 0;
  animation: fadeInOut 2s infinite;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.5));
}

.loading-text span:nth-child(2) { animation-delay: 0.1s; }
.loading-text span:nth-child(3) { animation-delay: 0.2s; }
.loading-text span:nth-child(4) { animation-delay: 0.3s; }
.loading-text span:nth-child(5) { animation-delay: 0.4s; }
.loading-text span:nth-child(6) { animation-delay: 0.5s; }
.loading-text span:nth-child(7) { animation-delay: 0.6s; }
.loading-text span:nth-child(8) { animation-delay: 0.7s; }
.loading-text span:nth-child(9) { animation-delay: 0.8s; }
.loading-text span:nth-child(10) { animation-delay: 0.9s; }

@keyframes rotate1 {
  0% { transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% { transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); }
}

@keyframes rotate2 {
  0% { transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% { transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); }
}

@keyframes rotate3 {
  0% { transform: rotateX(-40deg) rotateY(25deg) rotateZ(0deg); }
  100% { transform: rotateX(-40deg) rotateY(25deg) rotateZ(360deg); }
}

@keyframes pulse {
  0%, 100% { transform: translate(-50%, -50%) scale(0.8); opacity: 0.5; }
  50% { transform: translate(-50%, -50%) scale(1.2); opacity: 1; }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.2; transform: translateY(0); }
  50% { opacity: 1; transform: translateY(-5px); }
}
