@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* General resets and settings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

/* Full-page background for the tools page */
.toolsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b0b0b; /* Dark background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  opacity: 0.9; /* Slight transparency */
}

/* Tools container */
.toolsContainer {
  width: 90%;
  max-width: 800px;
  margin: 40px auto;
  background-image: radial-gradient(circle, #250000, #0b0b0b); /* Dark red gradient */
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.8); /* Intense shadow */
  border-top: 2px solid rgba(255, 0, 0, 0.8); /* Red border */
  border-left: 2px solid rgba(255, 0, 0, 0.8);
  backdrop-filter: blur(7px);
  border-radius: 20px;
  padding: 30px;
  color: #f2f2f2;
}

/* Heading styles */
.heading {
  font-size: 38px; /* Match homepage heading size */
  font-weight: 700; /* Bold for consistency */
  color: #ff1a1a; /* Blood-red color */
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 0 0 20px rgba(255, 0, 0, 0.7); /* Glowing effect */
}

/* Tools list styling */
.toolsList {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.toolItem {
  margin: 20px 0;
}

.toolTitle {
  color: #ff4d4d; /* Blood-red for titles */
  font-size: 24px; /* Match homepage */
  margin-bottom: 10px;
}

.toolLink {
  color: #f2f2f2; /* Light color for links */
  text-decoration: none;
  font-size: 18px; /* Consistent font size */
  transition: color 0.3s ease; /* Transition for hover effect */
}

.toolLink:hover {
  color: #ff4d4d; /* Hover color */
  text-decoration: underline; /* Underline on hover */
}

/* Responsive Design */
@media (max-width: 850px) {
  .toolsContainer {
    padding: 20px; /* Less padding for smaller screens */
  }

  .heading {
    font-size: 28px; /* Adjust heading size for smaller screens */
  }

  .toolTitle {
    font-size: 20px; /* Adjust tool title size */
  }

  .toolLink {
    font-size: 16px; /* Adjust link size */
  }
}

@media (max-width: 410px) {
  .toolsContainer {
    width: 100%; /* Full width on very small screens */
    border-radius: 0; /* No border radius */
  }

  .heading {
    font-size: 24px; /* Smaller heading for mobile */
  }

  .toolTitle {
    font-size: 18px; /* Smaller tool title */
  }

  .toolLink {
    font-size: 14px; /* Smaller link size */
  }
}

.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #fff;
  padding: 2rem 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
}

.toolsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.toolCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.toolCard:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 77, 77, 0.3);
}

.toolIcon {
  font-size: 2.5rem;
  color: #ff4d4d;
}

.toolInfo {
  flex: 1;
}

.toolInfo h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.toolInfo span {
  color: #ff4d4d;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.toolInfo p {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  line-height: 1.6;
}

.downloadButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  color: white;
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  justify-content: center;
}

.downloadButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.note {
  text-align: center;
  margin-top: 3rem;
  color: rgba(255, 255, 255, 0.7);
}

.note a {
  color: #ff4d4d;
  text-decoration: none;
  font-weight: 500;
}

.note a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem;
  }

  .toolCard {
    padding: 1.5rem;
  }

  .toolIcon {
    font-size: 2rem;
  }

  .toolInfo h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding-top: 1rem;
  }

  .toolsGrid {
    grid-template-columns: 1fr;
  }

  .header h1 {
    font-size: 1.8rem;
  }
}
