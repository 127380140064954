@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

/* Remove the body styling since it affects all pages */

.root {
  min-height: calc(100vh + 100px); /* Increased height to compensate for negative margin */
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  margin-top: -100px; /* Move background up */
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 2rem 0; /* Add some vertical padding */
  margin-top: 100px; /* Compensate for root's negative margin */
  min-height: 100vh; /* Ensure full height */
}

.backLink {
  position: absolute;
  top: 8rem;
  left: 20rem;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.backLink:hover {
  color: #ff4d4d;
}

.forgotPassword {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.7);
  border-top: 1px solid rgba(255, 0, 0, 0.5);
  border-left: 1px solid rgba(255, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  padding: 30px;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.forgotPassword h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ff0000;
}

.forgotPassword input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 0, 0, 0.5);
  outline: none;
  background-color: #2a2a2a;
  color: #fff;
}

.forgotPassword input::placeholder {
  color: #bbb;
}

.forgotPassword button {
  background: linear-gradient(135deg, #ff4d4d, #b30000);
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px; /* Ensuring space below the button */
}

.forgotPassword button:hover {
  background: linear-gradient(135deg, #b30000, #660000);
  transform: scale(0.98);
}

.forgotPassword a {
  color: #ff0000;
  margin-top: 10px;
}

.forgotPassword a:hover {
  text-decoration: underline;
}

.forgotPassword .message {
  margin-top: 20px;
  font-size: 16px;
}

.forgotPassword .message.success {
  color: #28a745;
}

.forgotPassword .message.error {
  color: #dc3545;
}

.formBox {
  background: rgba(255, 255, 255, 0.05);
  padding: 2.5rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%; /* Take full width of container */
}

.formHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.formHeader h1 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.formHeader p {
  color: rgba(255, 255, 255, 0.7);
}

.inputWrapper {
  position: relative;
  margin-bottom: 1.5rem;
}

.inputIcon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.2rem;
}

.inputWrapper input {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.inputWrapper input:focus {
  outline: none;
  border-color: #ff4d4d;
  background: rgba(255, 255, 255, 0.08);
}

.resetButton {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.resetButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.resetButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.loadingSpinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
}

.helpText {
  margin-top: 1.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.helpText p {
  margin: 0.5rem 0;
}

.helpText a {
  color: #ff4d4d;
  text-decoration: none;
  font-weight: 600;
  margin-left: 0.5rem;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@media (max-width: 850px) {
  .forgotPassword {
    padding: 20px;
    max-width: 90%;
  }

  .forgotPassword h1 {
    font-size: 20px;
  }

  .forgotPassword input {
    width: 100%;
  }

  .forgotPassword button {
    font-size: 16px;
    padding: 8px 15px;
  }

  .forgotPassword .message {
    font-size: 14px;
  }

  .forgotPassword a {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
    max-width: 90%; /* Take more width on mobile */
  }

  .formBox {
    padding: 1.5rem;
  }

  .backLink {
    top: 1rem;
    left: 1rem;
  }

  .formHeader h1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .formHeader h1 {
    font-size: 1.5rem;
  }

  .formHeader p {
    font-size: 0.9rem;
  }
}

@media (max-width: 410px) {
  .forgotPassword {
    padding: 15px;
    max-width: 90%;
    border-radius: 15px;
  }

  .forgotPassword h1 {
    font-size: 18px;
  }

  .forgotPassword input {
    padding: 8px;
  }

  .forgotPassword button {
    font-size: 14px;
    padding: 8px 12px;
  }

  .forgotPassword .message {
    font-size: 12px;
  }

  .forgotPassword a {
    font-size: 12px;
  }
}
