/* AboutUs.module.css */

.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #fff;
}

/* Main container styling */
.aboutUs {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #ffffff; /* Neutral text color for contrast */
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

/* Hero Section */
.hero {
  text-align: center;
  padding: 4rem 0;
  position: relative;
}

.hero h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
}

.glowBar {
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #ff4d4d, #f9cb28);
  margin: 0 auto;
  border-radius: 2px;
  position: relative;
}

.glowBar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(8px);
  opacity: 0.7;
}

/* Features Grid */
.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.featureCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease;
}

.featureCard:hover {
  transform: translateY(-5px);
}

.featureIcon {
  font-size: 2.5rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
}

/* Mission Section */
.mission {
  background: linear-gradient(145deg, rgba(255, 77, 77, 0.1), rgba(249, 203, 40, 0.1));
  border-radius: 12px;
  padding: 3rem 2rem;
  text-align: center;
  margin: 4rem 0;
}

.missionIcon {
  font-size: 3rem;
  color: #f9cb28;
  margin-bottom: 1rem;
}

/* Services Section */
.services {
  margin: 4rem 0;
}

.services h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.servicesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.serviceCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
}

.serviceCard h3 {
  color: #ff4d4d;
  margin-bottom: 1rem;
}

.serviceCard ul {
  list-style: none;
  padding: 0;
}

.serviceCard li {
  margin: 0.5rem 0;
  color: rgba(255, 255, 255, 0.8);
}

.serviceCard li::before {
  content: "→";
  color: #ff4d4d;
  margin-right: 0.5rem;
}


/* Paragraph Styling */
.aboutUs p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9); /* Slightly muted white for better readability */
  margin-bottom: 1.5rem;
}

/* List Styling */
.aboutUs ul, .aboutUs ol {
  margin-left: 2rem;
  padding-left: 1rem;
}

.aboutUs li {
  margin-bottom: 1rem;
  font-size: 1rem;
  position: relative;
  padding-left: 1.5rem;
  color: rgba(255, 255, 255, 0.85);
}

.aboutUs li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #00ffff;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.6), 0 0 25px rgba(0, 255, 255, 0.4);
  font-size: 1.5rem;
  line-height: 1rem;
}

/* Call to Action Section */
.cta {
  background: linear-gradient(145deg, rgba(255, 77, 77, 0.2), rgba(249, 203, 40, 0.2));
  border-radius: 12px;
  padding: 4rem 2rem;
  text-align: center;
  margin: 4rem 0;
}

.cta h3 {
  font-size: 2.8rem;
  color: #00ffff;
  text-shadow: 0 0 20px rgba(0, 255, 255, 0.6), 0 0 40px rgba(0, 255, 255, 0.4);
  margin-bottom: 1rem;
}

.cta p {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  color: rgba(255, 0, 0, 0.95);
}

.ctaButton {
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  border: none;
  color: white;
  padding: 1rem 3rem;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 2rem auto;
}

.ctaIcon {
  font-size: 1.2rem;
}

/* Sign Up Button */
.signupBtn {
  display: inline-block;
  background-color: #00ffff;
  color: #0a0a0a;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 1rem 2.5rem;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.6), 0 0 30px rgba(0, 255, 255, 0.4);
  transition: all 0.3s ease-in-out;
}

.signupBtn:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.8), 0 0 60px rgba(0, 255, 255, 0.6);
}

/* Content Sections */
.contentSection {
  margin-bottom: 2.5rem;
  padding: 2rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.2), 0 0 30px rgba(0, 255, 255, 0.15);
}

/* Links */
.aboutUs a {
  color: #383838;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.aboutUs a:hover {
  color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .aboutUs h1 {
    font-size: 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .aboutUs {
    padding: 2rem 1rem;
  }

  .servicesGrid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .ctaButton {
    padding: 0.8rem 2rem;
    font-size: 1rem;
  }
}