@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* General resets and settings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

/* Full-page background and centering */
.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Changed from center to flex-start */
  background-color: #0b0b0b;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Changed from height to min-height */
  width: 100vw;
  opacity: 0.95; /* Slightly increased transparency */
  padding-bottom: 2rem; /* Added padding at bottom */
  overflow-y: auto; /* Added overflow control */
}

/* Selection color */
::selection {
  color: #f2f2f2;
  background: #d60000;
}

/* Main content container */
.header {
  height: 85vh;
  width: 90%;
  background-image: radial-gradient(circle, #250000, #0b0b0b);
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.8);
  border: 2px solid rgba(255, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 2rem;
  position: relative;
  animation: headerGlow 4s ease-in-out infinite;
}

/* Animation keyframes */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Update the glow effect to be more natural */
@keyframes subtleGlow {
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
  }
}

/* Create individual glow animations for different elements */
@keyframes headerGlow {
  0% {
    border-color: rgba(255, 0, 0, 0.5);
  }
  50% {
    border-color: rgba(255, 0, 0, 1);
  }
  100% {
    border-color: rgba(255, 0, 0, 0.5);
  }
}

@keyframes textGlow {
  0% {
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.3);
  }
  50% {
    text-shadow: 0 0 15px rgba(255, 0, 0, 0.6);
  }
  100% {
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.3);
  }
}

/* Content containers */
.contentContainer {
  background: rgba(0, 0, 0, 0.4); /* More transparent background */
  backdrop-filter: blur(8px);
  border-radius: 15px;
  padding: 2rem;
  margin: 2rem 0; /* Increased margin to allow glow to spread */
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease, box-shadow 0.3s ease;
  animation: subtleGlow 6s ease-in-out infinite;
  position: relative;
  z-index: 1;
}

.contentContainer:hover {
  background: rgba(0, 0, 0, 0.5); /* Slightly less transparent on hover */
  border: 1px solid rgba(255, 0, 0, 0.15); /* Add slight red tint to border on hover */
  transform: translateY(-2px);
  box-shadow: 0 0 40px rgba(255, 0, 0, 0.3); /* Softer hover glow */
  z-index: 2; /* Bring hovered container to front */
}

.visible {
  opacity: 1;
  transform: translateY(0);
  /* Remove the previous glow animation from visible class */
}

.slideLeft {
  animation: slideInLeft 0.8s ease-out forwards;
}

.slideRight {
  animation: slideInRight 0.8s ease-out forwards;
}

/* Text content alignment */
.textContent {
  width: 70%;
  margin: 0 auto;
  margin-top: 50px;
  color: #f2f2f2;
  text-align: left;
  padding: 1rem;
  overflow: hidden;
}

.textContent h1, .textContent h2 {
  color: #ff5f5f;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  animation: textGlow 5s ease-in-out infinite;
}

.textContent h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center; /* Center align the heading */
}

.textContent h2 {
  margin-top: 10px;
  padding-left: 25px;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left; /* Center align the subheading */
}

/* List styling for better alignment */
.textContent ul {
  list-style: none;
  margin: 1.5rem auto;
  padding: 0;
  width: 100%;
}

.textContent ul li {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  position: relative;
  padding-left: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.textContent ul li::before {
  content: "✔";
  color: #d60000;
  font-size: 1.2rem;
  position: absolute;  
  left: 0;
  top: 0.3rem;
}

.textContent p {
  padding-left: 25px;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

/* Hero Section Styles */
.heroSection {
  text-align: center;
  padding: 4rem 2rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  margin: 2rem auto;
  max-width: 1200px;
  color: #ffffff;
}

.heroSection h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ff3366, #ff0000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.heroSection h2 {
  font-size: 1.8rem;
  color: #f0f0f0;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.heroSection p {
  font-size: 1.2rem;
  color: #cccccc;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  line-height: 1.6;
}

.keyFeatures {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
  flex-wrap: wrap;
}

.keyFeatures div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  border-radius: 10px;
  border: 1px solid rgba(255, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.keyFeatures div:hover {
  transform: translateY(-5px);
  background: rgba(255, 0, 0, 0.2);
}

.keyFeatures svg {
  font-size: 1.5rem;
  color: #ff3366;
}

.keyFeatures span {
  color: #ffffff;
  font-weight: 500;
}

/* Media Queries */
@media (max-width: 768px) {
  .heroSection h1 {
    font-size: 2rem;
  }

  .heroSection h2 {
    font-size: 1.4rem;
  }

  .heroSection p {
    font-size: 1rem;
  }

  .keyFeatures {
    flex-direction: column;
    gap: 1rem;
  }

  .keyFeatures div {
    width: 100%;
    justify-content: center;
  }
}

/* Responsive fixes */
@media (max-width: 768px) {
  .contentContainer {
    background: rgba(0, 0, 0, 0.1); /* More transparent background */
    margin: 1.5rem 0; /* Slightly reduced margin for mobile */
    padding: 1rem;
    margin-bottom: 1px;
  }

  .textContent {
    width: 95%;
    margin-top: 20px;
    padding: 0.5rem;
  }

  .textContent h1 {
    font-size: 1.5rem;
  }

  .textContent h2 {
    font-size: 1.2rem;
  }

  .textContent p, .textContent li {
    font-size: 0.9rem;
  }

  .textContent {
    width: 100%; /* Allow more width for smaller screens */
    margin-bottom: 100px;
  }

  .wrapper {
    min-height: 120vh; /* Ensure background covers entire scrollable area */
    padding-bottom: 1rem;
  }

  .textContent h1 {
    margin-top: 10px;
    font-size: 2rem;
  }

  .textContent h2 {
    font-size: 1.5rem;
  }

  .textContent ul li {
    font-size: 1rem;
    padding-left: 1.5rem;
  }

  .textContent p {
    font-size: 1rem;
    padding-left: 1.5rem;
  }

  .ispWarning {
    width: 90%;
    margin-bottom: 600px;
  }

  .warning {
    top: 70px;
    right: 10px;
    max-width: 200px; /* Reduced from 250px */
  }

  .warningContent {
    padding: 0.5rem; /* Reduced from 0.75rem */
  }

  .warningIcon {
    font-size: 1rem; /* Reduced from 1.2rem */
  }

  .warningContent p {
    font-size: 0.7rem; /* Reduced from 0.8rem */
    line-height: 1.2; /* Reduced line height */
  }

  .modal {
    max-width: 90%; /* Adjust modal width for tablets */
    padding: 1.5rem; /* Reduce padding */
  }

  .modal h2 {
    font-size: 1.4rem; /* Decrease heading size */
  }

  .steps {
    gap: 1rem; /* Reduce space between steps */
  }

  .closeButton {
    padding: 0.6rem; /* Adjust close button padding */
    font-size: 0.9rem; /* Decrease button font size */
  }
}

/* New styles */
.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #fff;
  overflow-x: hidden;
}

/* Hero Section */
.hero {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: radial-gradient(circle at 50% 50%, rgba(255, 0, 0, 0.1) 0%, transparent 60%);
  position: relative;
}

.heroContent {
  text-align: center;
  max-width: 800px;
  padding: 2rem;
  z-index: 1;
}

.hero h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight {
  color: #ff4d4d;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.8);
}

.ctaButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.primaryButton, .secondaryButton {
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
}

.primaryButton {
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  color: #fff;
  border: none;
}

.secondaryButton {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

.primaryButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.secondaryButton:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

/* Features Section */
.features {
  padding: 6rem 2rem;
  background: rgba(0, 0, 0, 0.3);
}

.features h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.featureCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.featureCard:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 77, 77, 0.3);
}

.featureIcon {
  font-size: 2.5rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
}

.featureCard h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.featureCard p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

/* Testimonials Section */
.testimonials {
  padding: 6rem 2rem;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
}

.testimonials h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reviewsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto 2rem auto;
}

.reviewCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  text-align: left;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.reviewCard:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 77, 77, 0.3);
}

.quoteIcon {
  color: rgba(255, 77, 77, 0.3);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.reviewContent {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.stars {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.star {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.2);
}

.star.filled {
  color: #ff4d4d;
}

.reviewAuthor {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1rem;
}

.reviewAuthor h4 {
  color: #fff;
  margin-bottom: 0.25rem;
  font-size: 1.1rem;
}

.reviewAuthor span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.viewAllReviews {
  margin-top: 3rem;
}

.loadingReviews {
  grid-column: 1 / -1;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  padding: 2rem;
}

@media (max-width: 768px) {
  .testimonials {
    padding: 4rem 1rem;
  }

  .testimonials h2 {
    font-size: 2rem;
  }

  .reviewsGrid {
    grid-template-columns: 1fr;
  }

  .reviewCard {
    padding: 1.5rem;
  }
}

/* Warning Section */
.warning {
  position: fixed;
  top: 90px; /* Positioned below the navbar */
  left: 2px;
  z-index: 1000;
  width: 515px;
  max-width: 1200px;
}

.warningContent {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 8px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(5px);
  animation: pulse 2s infinite;
}

.warningIcon {
  font-size: 1.2rem;
  color: #ff4d4d;
  flex-shrink: 0;
}

.warningContent p {
  font-size: 0.7rem;
  margin: 0;
  line-height: 1.3;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(255, 0, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 0, 0, 0); }
}

/* Pricing Section Styles */
.pricing {
  padding: 4rem 2rem;
  background: rgba(0, 0, 0, 0.3);
}

.pricing h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .ctaButtons {
    flex-direction: column;
  }

  .features {
    padding: 4rem 1rem;
  }

  .featureGrid {
    grid-template-columns: 1fr;
  }

  .warning {
    top: 722px;
    left: 8px;
    max-width: 380px; /* Reduced from 250px */
  }

  .warningContent {
    padding: 0.1rem; /* Reduced from 0.75rem */
  }

  .warningIcon {
    font-size: 0.9rem; /* Reduced from 1.2rem */
  }

  .warningContent p {
    font-size: 0.7rem; /* Reduced from 0.8rem */
    line-height: 1.2; /* Reduced line height */
  }

  .warningContent {
    flex-direction: column;
    text-align: center;
  }

  .modal {
    max-width: 90%; /* Adjust modal width for tablets */
    padding: 1.5rem; /* Reduce padding */
  }

  .modal h2 {
    font-size: 1.4rem; /* Decrease heading size */
  }

  .steps {
    gap: 1rem; /* Reduce space between steps */
  }

  .closeButton {
    padding: 0.6rem; /* Adjust close button padding */
    font-size: 0.9rem; /* Decrease button font size */
  }
}

/* Add a new breakpoint for even smaller devices */
@media (max-width: 380px) {
  .warning {
    max-width: 180px;
  }

  .warningContent {
    padding: 0.4rem;
  }

  .warningIcon {
    font-size: 0.9rem;
  }

  .warningContent p {
    font-size: 0.65rem;
  }
}

.protocols {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  margin: -0.5rem 0 1.5rem 0;
  font-weight: 500;
  letter-spacing: 2px;
}

/* Help Text and Modal Styles */
.helpText {
  margin-top: 1rem;
}

.helpButton {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 auto;
  cursor: pointer;
  transition: color 0.3s ease;
}

.helpButton:hover {
  color: #ff4d4d;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  will-change: opacity;
}

.modal {
  background: #1a1a1a;
  border-radius: 15px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  border: 1px solid rgba(255, 77, 77, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  will-change: transform, opacity;
}

.modal h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.step {
  position: relative;
  padding-left: 3rem;
}

.stepNumber {
  position: absolute;
  left: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  will-change: transform;
}

.step h3 {
  color: #fff;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.step p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
}

.step ul {
  list-style: none;
  padding-left: 1rem;
}

.step ul li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.3rem;
  position: relative;
}

.step ul li:before {
  content: "•";
  color: #ff4d4d;
  position: absolute;
  left: -1rem;
}

.note {
  color: #ff4d4d !important;
  font-weight: 500;
  margin-top: 0.5rem;
}

.closeButton {
  display: block;
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 77, 77, 0.1);
  border: 1px solid rgba(255, 77, 77, 0.3);
  color: #fff;
  border-radius: 8px;
  margin-top: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.closeButton:hover {
  background: rgba(255, 77, 77, 0.2);
}

@media (max-width: 480px) {
  .modal {
    max-width: 95%; /* Further adjust modal width for mobile */
    padding: 1rem; /* Further reduce padding */
  }

  .modal h2 {
    font-size: 1.2rem; /* Further decrease heading size */
  }

  .step h3 {
    font-size: 1rem; /* Decrease step heading size */
  }

  .step p,
  .step ul li {
    font-size: 0.8rem; /* Decrease text size in steps */
  }
}

/* Footer Styles */
.footer {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
  padding: 4rem 2rem 1rem 2rem;
  margin-top: 4rem;
  border-top: 1px solid rgba(255, 77, 77, 0.2);
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  margin-bottom: 3rem;
}

.footerSection h3 {
  color: #ff4d4d;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.footerSection p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  font-size: 0.95rem;
}

.footerSection ul {
  list-style: none;
  padding: 0;
}

.footerSection ul li {
  margin-bottom: 0.8rem;
}

.footerSection ul li a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.95rem;
}

.footerSection ul li a:hover {
  color: #ff4d4d;
}

.footerBottom {
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footerBottom p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 3rem 1rem 1rem 1rem;
  }

  .footerContent {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footerSection {
    text-align: center;
  }

  .footerSection ul li {
    margin-bottom: 0.6rem;
  }
}

/* Telegram Link Styles */
.telegramLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.telegramLink:hover {
  color: #ff4d4d;
}

.telegramLink svg {
  font-size: 1.2rem;
}
