@import './styles/theme.css';

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: var(--space-4);
  min-height: 100vh;
  background: var(--bg-primary);
}

.loading {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--bg-accent);
  border-top-color: var(--accent-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

h2 {
  color: #9cb3ff;
}

.articleHeader {
  text-align: center;
  margin: var(--space-6) 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
}

.title {
  font-family: var(--font-display);
  font-size: 3.5rem;
  font-weight: 700;
  background: var(--accent-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: var(--space-4);
  line-height: 1.2;
}

.metadata {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-3);
  color: var(--text-muted);
  font-size: 0.9rem;
}

.divider {
  width: 4px;
  height: 4px;
  background: var(--text-muted);
  border-radius: 50%;
}

.editButton {
  position: fixed;
  bottom: var(--space-4);
  right: var(--space-4);
  background: var(--accent-gradient);
  color: var(--text-primary);
  padding: var(--space-3) var(--space-4);
  border-radius: var(--radius-full);
  text-decoration: none;
  font-weight: 500;
  box-shadow: var(--shadow-lg);
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  gap: var(--space-2);
}

.editButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
}

.content {
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.8;
  font-size: 1.1rem;
}

.content h1,
.content h2,
.content h3 {
  font-family: var(--font-display);
  color: var(--text-primary);
  margin: var(--space-5) 0 var(--space-3);
}

.content h1 { font-size: 2.5rem; }
.content h2 { font-size: 2rem; }
.content h3 { font-size: 1.5rem; }

.content p {
  margin-bottom: var(--space-4);
  color: var(--text-secondary);
}

.content img {
  width: 100%;
  border-radius: var(--radius-lg);
  margin: var(--space-5) 0;
  box-shadow: var(--shadow-lg);
}

.content blockquote {
  border-left: 4px solid var(--accent-primary);
  padding-left: var(--space-4);
  margin: var(--space-4) 0;
  font-style: italic;
  color: var(--text-muted);
}

.content pre {
  background: var(--bg-secondary);
  padding: var(--space-4);
  border-radius: var(--radius-md);
  overflow-x: auto;
  margin: var(--space-4) 0;
}

.content code {
  font-family: 'Fira Code', monospace;
  font-size: 0.9em;
}

a {
  color: #9cb3ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #bbccff;
}

.reactionBar {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 2rem 0;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.reactionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(156, 179, 255, 0.2);
  border-radius: 20px;
  background: transparent;
  color: #9cb3ff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.reactionButton:hover {
  background: rgba(156, 179, 255, 0.1);
  transform: translateY(-2px);
}

.reactionButton.active {
  background: rgba(156, 179, 255, 0.2);
  border-color: #9cb3ff;
}

.reactionButton svg {
  width: 1.2em;
  height: 1.2em;
}

@media (max-width: 768px) {
  .container {
    padding: var(--space-3);
  }

  .title {
    font-size: 2.5rem;
  }

  .metadata {
    flex-direction: column;
    gap: var(--space-2);
  }

  .divider {
    display: none;
  }

  .editButton {
    bottom: var(--space-3);
    right: var(--space-3);
    width: calc(100% - var(--space-6));
  }

  .content {
    font-size: 1rem;
  }

  .reactionBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 1rem;
    z-index: 100;
  }
}
