/* Full Page Layout */
.speed-test-page {
  width: 100%;
  min-height: 100vh;
  background-color: #0b0b0b;
  background-image: radial-gradient(circle, #250000, #0b0b0b);
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

/* Page Title */
.page-title {
  text-align: center;
  margin-bottom: -15px;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  letter-spacing: 2px;
}

/* Speed Test Section */
.speed-test-section {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex: 1;
  min-height: 400px;
}

/* Speed Test Box */
.speed-test-box {
  flex: 1;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  padding: 30px;
  border-radius: 20px;
  max-width: 800px;
  min-width: 400px;
  border: 2px solid rgba(255, 0, 0, 0.8);
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
  animation: headerGlow 4s ease-in-out infinite;
}

.speed-test-box:hover {
  transform: translateY(-5px);
  border: 2px solid rgba(255, 0, 0, 1);
  background: rgba(0, 0, 0, 0.5);
}

/* Title for Each Speed Test */
.test-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  margin-top: -15px !important;
  background: linear-gradient(45deg, #ff3366, #ff0000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
  margin-right: auto;  /* Added to push refresh button to the right */
}

/* BDIX Speed Test */
.bdix-speed-test, 
.main-speed-test {
  width: 100%;
  height: 90%;
  min-height: 500px;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid rgba(255, 0, 0, 0.8);
  position: relative;
  transition: all 0.3s ease;
}

.bdix-speed-test {
  border-color: #ff3333;
  box-shadow: 0 0 15px rgba(255, 51, 51, 0.3);
}

.main-speed-test {
  border-color: #cc0000;
  box-shadow: 0 0 15px rgba(204, 0, 0, 0.3);
}

.bdix-speed-test:hover,
.main-speed-test:hover {
  box-shadow: 0 0 30px rgba(255, 0, 0, 0.4);
}

.bdix-speed-test iframe, 
.main-speed-test iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
}

/* Instructions Section */
.instructions {
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  margin: 20px auto;
  padding: 2rem;
  max-width: 1400px;
  text-align: left;
  border: 2px solid rgba(255, 0, 0, 0.8);
  border-radius: 20px;
  animation: headerGlow 4s ease-in-out infinite;
}

.instructions h2 {
  background: linear-gradient(45deg, #ff3366, #ff0000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.instructions p {
  color: #f2f2f2;
  margin: 10px 0;
  font-size: 1.2rem;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

/* Responsive Design */
@media (max-width: 1600px) {
  .speed-test-box {
    max-width: 700px;
  }
}

@media (max-width: 1024px) {
  .speed-test-section {
    flex-direction: column;
    align-items: center;
  }

  .speed-test-box {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .speed-test-section {
    flex-direction: column;
    align-items: center;
  }

  .speed-test-box {
    width: 100%;
  }
}

/* Add this new keyframe animation */
@keyframes neonPulse {
  0% { box-shadow: 0 0 15px rgba(255, 0, 0, 0.3); }
  50% { box-shadow: 0 0 25px rgba(255, 0, 0, 0.5); }
  100% { box-shadow: 0 0 15px rgba(255, 0, 0, 0.3); }
}

.important-note {
  background: rgba(255, 0, 0, 0.1);
  border-left: 4px solid #ff0000;
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  color: #f2f2f2;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 0 10px 10px 0;
  animation: subtleGlow 6s ease-in-out infinite;
}

@keyframes headerGlow {
  0% {
    border-color: rgba(255, 0, 0, 0.5);
  }
  50% {
    border-color: rgba(255, 0, 0, 1);
  }
  100% {
    border-color: rgba(255, 0, 0, 0.5);
  }
}

@keyframes subtleGlow {
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
  }
}

.speed-note {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2px;
  text-align: center;
  font-style: italic;
}

.refresh-btn {
  background: none;
  border: none;
  color: rgba(255, 0, 0, 0.8);
  cursor: pointer;
  padding: 12px;  /* Increased padding */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  border: 2px solid rgba(255, 0, 0, 0.5);
  margin-left: 15px;  /* Added margin to separate from title */
  position: absolute;  /* Changed from relative */
  right: 20px;  /* Position from right */
  top: 45px;  /* Move down by 45px */
  z-index: 10;  /* Ensure button stays on top */
  background: rgba(0, 0, 0, 0.7);  /* Added dark background */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.refresh-btn:hover {
  transform: rotate(180deg);
  color: rgba(255, 0, 0, 1);
  border-color: rgba(255, 0, 0, 1);
  background: rgba(0, 0, 0, 0.9);
}

.refresh-btn svg {
  font-size: 1.4rem;  /* Increased size */
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .refresh-btn {
    padding: 10px;
    right: 15px;
    top: 40px;
  }
  
  .refresh-btn svg {
    font-size: 1.2rem;
  }
}

.speed-link {
  color: #ff3366;
  text-decoration: none;
  transition: color 0.3s ease;
}

.speed-link:hover {
  color: #ff0000;
  text-decoration: underline;
}
