/* src/pages/Contact.module.css */

.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  padding: 2rem 1rem;
}

.contactContainer {
  width: 90%;
  max-width: 1000px;
  margin: 2rem auto;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);

  backdrop-filter: blur(5px);
  border-radius: 25px;
  padding: 2rem;
  color: #f2f2f2;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cardIcon {
  font-size: 2.5rem;
  flex-shrink: 0;
}

.cardContent {
  flex-grow: 1;
}

.cardContent h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.cardContent p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.arrowIcon {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.5);
  transition: transform 0.3s ease;
}

.card:hover .arrowIcon {
  transform: translateX(5px);
  color: #ff4d4d;
}

.telegramBanner {
  background: linear-gradient(145deg, rgba(255, 77, 77, 0.1), rgba(249, 203, 40, 0.1));
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  border: 1px solid rgba(255, 77, 77, 0.2);
}

.telegramIcon {
  font-size: 3rem;
  color: #2AABEE;
}

.bannerContent h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.bannerContent p {
  color: rgba(255, 255, 255, 0.7);
}

.contactContainer h2 {
  font-size: 36px;
  font-weight: 600;
  color: #ff4d4d;
  text-align: center;
  margin-bottom: 20px;
}

.contactList {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.contactList li {
  margin: 20px 0;
}

.contactList a {
  color: #d82c2c;
  text-decoration: none;
  font-size: 18px;
}

.contactList a:hover {
  color: #ff4d4d;
  text-decoration: underline;
}

@media (max-width: 850px) {
  .contactContainer {
    padding: 1rem;
  }

  .contactContainer h2 {
    font-size: 28px;
  }

  .contactList a {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .contactContainer {
    padding: 1rem;
  }

  .header h1 {
    font-size: 2rem;
  }

  .card {
    padding: 1.5rem;
  }

  .telegramBanner {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.8rem;
  }

  .cardsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 410px) {
  .contactContainer {
    width: 100%;
    border-radius: 0px;
  }

  .contactContainer h2 {
    font-size: 24px;
  }

  .contactList a {
    font-size: 14px;
  }
}
