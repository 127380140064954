.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgba(32, 33, 36, 0.95);
  padding: 2rem;
}

.title {
  color: #ffffff;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.googleButton {
  padding: 12px 24px;
  background-color: rgba(156, 179, 255, 0.2);
  color: #9cb3ff;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.googleButton:hover {
  background-color: rgba(156, 179, 255, 0.3);
  transform: translateY(-1px);
}
