/* src/pages/PricingPage.module.css */

.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #fff;
  padding: 2rem 1rem;
}

.mainContent {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
}

.pricingContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.pricingCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 1.8rem;  /* reduced from 2rem */
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-height: auto; /* allows card to shrink */
}

.pricingCard:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 77, 77, 0.3);
}

.cardHeader {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.iconWrapper {
  font-size: 2rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
}

.cardHeader h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.subtitle {
  color: #ff4d4d;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.popularBadge {
  position: absolute;
  top: -12px;
  right: -12px;
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.8rem;
  color: #fff;
  font-weight: 600;
}

.pricingList {
  list-style: none;
  padding: 0;
}

.pricingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.pricingItem:hover {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.speed {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.price {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ff4d4d;
}

.currency {
  font-size: 0.8rem;
  margin-right: 2px;
}

.highlight {
  background: rgba(255, 77, 77, 0.1);
  border-radius: 8px;
}

.popular {
  background: linear-gradient(145deg, rgba(255, 77, 77, 0.1), rgba(249, 203, 40, 0.1));
  border: 1px solid rgba(255, 77, 77, 0.3);
  transform: scale(1.02);
}

.special {
  background: linear-gradient(145deg, rgba(0, 0, 0, 0.3), rgba(255, 77, 77, 0.1));
  border: 1px solid rgba(255, 77, 77, 0.2);
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem;
  }

  .pricingCard {
    padding: 1.5rem;
  }

  .popular {
    transform: scale(1);
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.8rem;
  }

  .pricingContainer {
    grid-template-columns: 1fr;
  }
}

.bestValue {
    background: linear-gradient(145deg, rgba(0, 0, 0, 0.95), rgba(59, 11, 11, 0.95));
    border: 1px solid rgb(255, 68, 68);
    transform: scale(1.05);
    box-shadow: 0 0 25px rgba(255, 68, 68, 0.3);
    position: relative;
    overflow: hidden;
}

.bestValue::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #ff4444, #ff8f8f, #ff4444);
    animation: shimmer 2s infinite linear;
}

.bestBadge {
    background: linear-gradient(90deg, #ff4444, #ff8f8f);
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.85em;
    margin-left: 10px;
    display: inline-block;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 10px rgba(255, 68, 68, 0.3);
    animation: pulse 2s infinite;
}

@keyframes shimmer {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.bonusNews {
  background-color: rgba(255, 255, 255, 0.034); /* Light transparent background */
  color: #ffffff; /* Bright red color for text */
  padding: 10px 10px; /* Padding for spacing */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Shadow effect */
  text-align: center; /* Centered text */
  font-size: 20px; /* Increased font size */
  font-weight: bold; /* Bold text */
  margin: 40px auto; /* Center the element */
  max-width: 1200px; /* Limit the width */
  border: 1px solid rgb(0, 217, 255); /* Border to match pricing cards */
  backdrop-filter: blur(5px); /* Glassmorphism effect */
  box-shadow: 0 0 20px rgb(60, 216, 255), 0 0 15px rgba(255, 7, 58, 0.6);
  animation: pulse 1.5s infinite; /* Pulsing animation */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .bonusNews {
    font-size: 18px; /* Slightly smaller font size for mobile */
    padding: 10px 15px; /* Reduced padding for smaller screens */
    margin: 15px auto; /* Adjusted margin */
  }
}

@media (max-width: 480px) {
  .bonusNews {
    font-size: 16px; /* Further reduce font size for smaller devices */
    padding: 8px 10px; /* Further reduced padding */
    max-width: 90%; /* Make it full width on very small screens */
  }
}

/* Pulsing animation */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.ftpNote {
    font-size: 0.7rem;
    color: rgba(255, 255, 255, 0.6);
    font-style: italic;
    margin-top: 5px;
    margin-bottom: -20px;
    text-align: center;
    overflow: hidden;
}

.howToBuyButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 77, 77, 0.3);
    border-radius: 8px;
}

.howToBuyButton:hover {
    color: #ff4d4d;
    background: rgba(255, 77, 77, 0.1);
}

.howToBuyButton svg {
    font-size: 1.2rem;
}

