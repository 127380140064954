@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

/* Flexbox centering for the wrapper */
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  background-image: radial-gradient(circle, #3b0b0b, #1a1a1a);
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

/* Container Styles */
.container {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.7);
  border-top: 1px solid rgba(255, 0, 0, 0.5);
  border-left: 1px solid rgba(255, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  padding: 40px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  margin: 100px auto 0; /* Centered horizontally with top margin */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
}

/* Title */
h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ff0000;
}

/* Input container */
.inputContainer {
  margin-bottom: 20px;
}

.inputContainer input {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(255, 0, 0, 0.5);
  outline: none;
  background-color: #2a2a2a;
  color: #fff;
}

.inputContainer input::placeholder {
  color: #bbb;
}

/* Button Styles */
.signupButton {
  background: linear-gradient(135deg, #ad2020, #b30000);
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 15px auto; /* Changed margin to auto for horizontal centering */
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: block; /* Added to ensure proper centering */
}

.signupButton:hover {
  background: linear-gradient(135deg, #b30000, #660000);
  transform: scale(0.98);
}

/* Google Button */
.buttonGoogleButton {
    background: linear-gradient(135deg, #ad2020, #b30000);
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    margin-left: 8px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 15px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border: none;
    
    /* Flexbox to align icon and text */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .googleIcon {
    margin-right: 8px; /* Spacing between the icon and text */
    font-size: 18px; /* Adjust icon size */
    vertical-align: middle; /* Align the icon with the text */
  }
  
  .buttonGoogleButton:hover {
    background: linear-gradient(135deg, #b30000, #660000);
    transform: scale(0.98);
  }
  

/* Message */
.message {
  margin-top: 20px;
  font-size: 16px;
  color: #dc3545;
}

.message.success {
  color: #28a745;
}

.message.error {
  color: #dc3545;
}

/* Back to Login Link */
.link {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  color: #ff0000;
  font-size: 14px;
}

.link:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .container {
    padding: 20px;
  }

  h2 {
    font-size: 20px;
  }

  .inputContainer input {
    width: calc(100% - 10px);
  }

  .message {
    font-size: 14px;
  }

  .link {
    font-size: 12px;
  }
}
.signinLink {
display: inline-block;
  margin-top: 6px;
  text-decoration: none;
  color: #ff0000;
  font-size: 14px;
}


.signinLink:hover {
  text-decoration: underline;
}

/* Back Button Styles */
.backButton {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
  background: none;
  margin-top: 50;
  border: none;
  color: #ff0000;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}
  
  .backButton svg {
    margin-right: 1px;
    padding-top: 3.5px;
  }
  
  .backButton:hover {
    color: #b30000;
    text-decoration: underline;
  }
  .passwordRequirements {
    color: #888;
    font-size: 0.80rem;
    transition: opacity 0.3s ease;
    text-align: left;
    margin-left: 12px;
}

.passwordRequirements span {
    display: block;
    margin-bottom: 5px;
}

.met {
    color: #4caf50; /* Green color for met requirements */
}

/* Loading spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 8px;
}

/* Disabled button states */
.signupButton:disabled,
.buttonGoogleButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem;
  margin-top: -100px; /* Move background up */

}

.backLink {
  position: absolute;
  top: 2rem;
  left: 2rem;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.backLink:hover {
  color: #ff4d4d;
}

.signupContainer {
  width: 100%;
  max-width: 500px; /* Increased from 400px */
  padding: 2rem;
}

.formContainer {
  background: rgba(255, 255, 255, 0.05);
  padding: 2.5rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%; /* Ensure it takes full container width */
}

.formHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.formHeader h1 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.formHeader p {
  color: rgba(255, 255, 255, 0.7);
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.inputWrapper {
  position: relative;
}

.inputWrapper input {
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.inputWrapper input:focus {
  outline: none;
  border-color: #ff4d4d;
  background: rgba(255, 255, 255, 0.08);
}

.requirements {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.requirement {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.requirement.met {
  color: #4CAF50;
}

.checkmark {
  font-size: 1.1rem;
}

.signupButton, .googleButton {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.signupButton {
  background: linear-gradient(45deg, #ff4d4d, #f9cb28);
  color: #fff;
  margin-bottom: 1.5rem;
}

.signupButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: rgba(255, 255, 255, 0.5);
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.divider span {
  padding: 0 1rem;
}

.googleButton {
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.googleButton:hover {
  background: rgba(255, 255, 255, 0.1);
}

.loginPrompt {
  text-align: center;
  margin-top: 1.5rem;
  font-size: small;
  color: rgba(255, 255, 255, 0.7);
}

.loginPrompt a {
  color: #ff4d4d;
  text-decoration: none;
  font-weight: 600;
}

.loadingState {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.spinner {
  width: 24px;
  height: 24px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-top-color: #ff4d4d;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .signupContainer {
    padding: 0.01rem !important;
    max-width: 100%; /* Take more width on mobile */
    margin-top: 20px;
  }

  .formContainer {
    padding: 1.5rem;
    padding: 1.2rem;
  }

  .backLink {
    top: 1rem;
    left: 1rem;
  }

  .inputWrapper input {
    padding: 1rem 0.8rem; /* Adjusted padding */
  }
}