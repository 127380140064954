@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


.notFoundContainer {
  margin-top: -80px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1e1e1e, #2d2d2d);
  padding-bottom: 200px;
}

.content {
  text-align: center;
  max-width: 600px;
}

.errorCode {
  font-size: 120px;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 0 #ff4d4d,
               4px 4px 0 #ff6666,
               6px 6px 0 #ff8080;
  letter-spacing: 15px;
  margin-bottom: 30px;
}

.zero {
  display: inline-block;
  animation: spin 10s linear infinite;
  color: #ff4d4d;
}

.messageContainer {
  margin-bottom: 40px;
}

.messageContainer h2 {
  color: #fff;
  font-size: 32px;
  margin-bottom: 15px;
}

.messageContainer p {
  color: #b3b3b3;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.buttonGroup {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.homeButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  text-decoration: none;
  transition: transform 0.2s, box-shadow 0.2s;
}

.homeButton {
  background: #ff4d4d;
  color: white;
}


.homeButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .notFoundContainer {
    padding: 20px;
  }

  .notFoundContainer h1 {
    font-size: 4rem;
  }

  .notFoundContainer p {
    font-size: 1.2rem;
  }

  .notFoundContainer a {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .errorCode {
    font-size: 80px;
    letter-spacing: 10px;
  }

  .messageContainer h2 {
    font-size: 24px;
  }

  .messageContainer p {
    font-size: 14px;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 15px;
  }
}
