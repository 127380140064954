/* Dark theme and sleek design */
.root {
  min-height: 100vh;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #fff;
}

.reviewsPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #0a0a0a 0%, #1a1a1a 100%);
  color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageTitle {
  font-size: 36px;
  margin-bottom: 40px;
  color: #ff4d4d;
  text-shadow: 0 0 15px rgba(255, 77, 77, 0.5);
}

.header {
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.header h1 {
  font-size: 2.5rem;
  background: linear-gradient(45deg, #fff, #ff4d4d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.filterSelect {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #c42c2c;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filterSelect:hover {
  background: rgba(255, 255, 255, 0.15);
}

.reviewsList {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  width: 100%;
}

.reviewsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

/* Read More Link */
.readMore {
  color: #ff4d4d; /* Style for Read More link */
  cursor: pointer; /* Change cursor to pointer for interaction */
  margin-left: 5px; /* Space between text */
}

.reviewItem {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  width: 350px; /* Increased width */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reviewCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.reviewItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
}

.reviewCard:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 77, 77, 0.3);
}

.reviewHeader {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-end; /* Align items to the start */
  margin-bottom: 15px;
}

.reviewContent {
  position: relative;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.6;
  font-size: 18px;
  color: #d9d9d9;
  margin-top: 10px;
  text-align: left;
}

.quoteIcon {
  color: rgba(255, 77, 77, 0.3);
  font-size: 1.2rem;
  margin: 0 0.5rem;
}

.reviewAuthor {
  font-size: 1rem; /* Keep author name bold and prominent */
  font-weight: bold;
  color: #ff4d4d;
  margin-top: -25px;
  margin-bottom: auto; /* Space between author name and date */
}

.reviewDate {
  font-size: 0.875rem; /* Slightly smaller font for date */
  color: #888888; /* Gray color for subtle contrast */
  text-align: left;
}

.reviewFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.reviewFooter h3 {
  color: #ff4d4d;
  font-size: 1.1rem;
  font-weight: 600;
}

.reviewFooter span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
}

/* Star rating styles */
.starRating {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-right: auto;
}

.star {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.2);
  font-size: 20px;
  color: #999;
}

.selected {
  color: #ff4d4d;
  color: #ffb400;
}

.noReviews {
  font-size: 20px;
  color: #999;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.loadingDots {
  display: flex;
  gap: 0.5rem;
}

.loadingDots span {
  width: 10px;
  height: 10px;
  background-color: #ff4d4d;
  border-radius: 50%;
  animation: bounce 0.5s ease-in-out infinite;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.1s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .reviewsPage {
    padding: 2rem 1rem;
  }

  .reviewItem {
    width: 100%;
  }

  .header h1 {
    font-size: 2rem;
  }

  .reviewCard {
    padding: 1.5rem;
  }

  .reviewContent {
    font-size: 1rem;
  }
}

.loadMoreContainer {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
}

.loadMoreButton {
    padding: 1rem 2rem;
    background: linear-gradient(135deg, #ff4d4d 0%, #c42c2c 100%);
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(255, 77, 77, 0.2);
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
}

.loadMoreButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(255, 77, 77, 0.3);
    background: linear-gradient(135deg, #ff6666 0%, #d83232 100%);
}

.loadMoreButton:active {
    transform: translateY(0);
}

.loadMoreButton:disabled {
    background: linear-gradient(135deg, #666666 0%, #444444 100%);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
    opacity: 0.7;
}

.loadMoreButton:disabled:hover {
    transform: none;
    box-shadow: none;
}

/* Loading state */
.loadMoreButton:disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .loadMoreButton {
        padding: 0.8rem 1.5rem;
        font-size: 1rem;
    }
}