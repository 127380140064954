@import './styles/theme.css';

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: var(--space-5);
}

.header {
  text-align: center;
  margin-bottom: var(--space-6);
}

.headerContent {
  max-width: 800px;
  margin: 0 auto;
}

.headerTitle {
  font-family: var(--font-display);
  font-size: 3rem;
  font-weight: 700;
  background: var(--accent-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: var(--space-3);
}

.headerSubtitle {
  color: var(--text-secondary);
  font-size: 1.1rem;
  margin-bottom: var(--space-5);
  line-height: 1.6;
  font-family: var(--font-sans);
  opacity: 0.8;
}

.searchBar {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.searchInput {
  width: 100%;
  padding: var(--space-3) var(--space-4);
  padding-left: var(--space-5);
  background: var(--bg-secondary);
  border: 1px solid var(--bg-accent);
  border-radius: var(--radius-full);
  color: var(--text-primary);
  font-size: 1rem;
  transition: var(--transition-normal);
}

.searchInput:focus {
  outline: none;
  border-color: var(--accent-primary);
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.searchIcon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.blogGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: var(--space-4);
  margin-bottom: var(--space-6);
}

.blogCard {
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  overflow: hidden;
  transition: var(--transition-normal);
  border: 1px solid var(--bg-accent);
}

.blogCard:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-lg);
  border-color: var(--accent-primary);
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.cardContent {
  padding: var(--spacing-lg);
}

.cardCategory {
  display: inline-block;
  padding: var(--spacing-xs) var(--spacing-md);
  background: var(--primary);
  color: var(--text-primary);
  border-radius: var(--radius-full);
  font-size: 0.875rem;
  margin-bottom: var(--spacing-md);
}

.cardTitle {
  font-size: 1.25rem;
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
}

.cardMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-tertiary);
  font-size: 0.875rem;
}

.deleteButton {
  background: var(--danger);
  color: white;
  border: none;
  padding: var(--space-2) var(--space-3);
  border-radius: var(--radius-md);
  cursor: pointer;
  opacity: 0.8;
  transition: var(--transition-fast);
}

.deleteButton:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .container {
    padding: var(--space-3);
  }

  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }

  .headerTitle {
    font-size: 2rem;
  }

  .searchBar {
    max-width: 100%;
  }

  .blogGrid {
    grid-template-columns: 1fr;
  }

  .writeButton {
    bottom: var(--space-3);
    right: var(--space-3);
    width: calc(100% - var(--space-6));
    text-align: center;
  }
}

.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.searchContainer {
  flex: 1;
  max-width: 600px;
  min-width: 200px;
  position: relative;
  margin: var(--space-5) auto;
}

.searchInput {
  width: 100%;
  padding: 12px 16px;
  font-size: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  transition: all 0.3s ease;
}

.searchInput:focus {
  outline: none;
  border-color: #9cb3ff;
  box-shadow: 0 0 0 3px rgba(156, 179, 255, 0.1);
}

.searchInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 25px;
}

.noResults {
  text-align: center;
  padding: 2rem;
  color: #e0e0e0;
  font-size: 1.1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  margin: 1rem 0;
}

.blogList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.blogCard {
  padding: 1.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.blogCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-color: #9cb3ff;
  background: rgba(255, 255, 255, 0.08);
}

.blogTitle {
  font-family: var(--font-display);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: var(--space-2);
  color: var(--text-primary);
}

.blogDate {
  color: #e0e0e0;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.readMore {
  display: inline-block;
  color: #9cb3ff;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  padding-bottom: 2px;
}

.readMore::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #9cb3ff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.readMore:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.writeButton {
  display: inline-block;
  padding: 12px 24px;
  background-color: rgba(156, 179, 255, 0.2);
  color: #9cb3ff;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  text-align: center;
}

.writeButton:hover {
  background-color: rgba(156, 179, 255, 0.3);
  color: #bbccff;
  transform: translateY(-1px);
}

.writeButton:active {
  transform: translateY(0);
}

.writeButton {
  position: fixed;
  bottom: var(--space-4);
  right: var(--space-4);
  background: var(--accent-gradient);
  color: var(--text-primary);
  padding: var(--space-3) var(--space-4);
  border-radius: var(--radius-full);
  text-decoration: none;
  font-weight: 500;
  box-shadow: var(--shadow-lg);
  transition: var(--transition-normal);
}

.writeButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
}

/* Loading animation */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #ffffff;
}

.loadingDots {
  display: flex;
  gap: 0.5rem;
}

.loadingDots span {
  width: 10px;
  height: 10px;
  background-color: #9cb3ff;
  border-radius: 50%;
  animation: bounce 0.5s ease-in-out infinite;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.1s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    margin: 1rem;
    padding: 1rem;
  }

  .header {
    font-size: 2rem;
  }

  .blogTitle {
    font-size: 1.25rem;
  }

  .writeButton {
    display: block;
    width: 100%;
  }

  .headerSection {
    flex-direction: column;
    align-items: stretch;
  }

  .searchContainer {
    max-width: 100%;
    order: -1;
  }

  .featuredBlog {
    grid-template-columns: 1fr;
  }

  .featuredImage {
    min-height: 200px;
  }

  .blogGrid {
    grid-template-columns: 1fr;
  }
}

/* Remove these classes */
.categories,
.categoryButton,
.categoryButton.active,
.category {
  display: none;
}

.featuredBlog {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
}

.featuredContent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.featuredLabel {
  color: #9cb3ff;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.featuredImage {
  height: 100%;
  min-height: 300px;
  background-size: cover;
  background-position: center;
}

.blogGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.blogCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.blogCard:hover {
  transform: translateY(-5px);
}

.blogImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid var(--bg-accent);
}

.blogContent {
  padding: var(--space-4);
}

.blogExcerpt {
  color: var(--text-secondary);
  margin-bottom: var(--space-4);
  line-height: 1.6;
}

.blogFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--space-3);
  border-top: 1px solid var(--bg-accent);
  flex-wrap: wrap;
  gap: 1rem;
}

.blogDate {
  color: var(--text-muted);
  font-size: 0.875rem;
}

.readMore {
  color: var(--accent-primary);
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: var(--space-1);
  transition: var(--transition-fast);
}

.readMore:hover {
  color: var(--accent-secondary);
}

.deleteButton {
  padding: 8px 16px;
  background-color: rgba(255, 0, 0, 0.2);
  color: #ff4d4d;
  border: 2px solid rgba(255, 0, 0, 0.5);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.deleteButton:hover {
  background-color: rgba(255, 0, 0, 0.3);
  color: #ff1a1a;
  border-color: rgba(255, 0, 0, 0.7);
}

.blogStats {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-muted);
  font-size: 0.9rem;
}

.statsItem {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.statsItem svg {
  width: 1em;
  height: 1em;
}
