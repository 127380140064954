.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background: var(--bg-secondary);
  box-shadow: var(--shadow-lg);
  border-radius: var(--radius-lg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--accent-primary);
}

.title {
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0;
}

.controls {
  display: flex;
  gap: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-size: 0.9rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.titleInput {
  font-size: 1.2rem;
  padding: 1rem;
  border: 2px solid var(--bg-accent);
  border-radius: var(--radius-md);
  background: var(--bg-primary);
  color: var(--text-primary);
  transition: all var(--transition-normal);
}

.titleInput:focus {
  outline: none;
  border-color: var(--accent-primary);
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.editor {
  border-radius: var(--radius-md);
  overflow: hidden;
}

.editor :global(.ql-toolbar) {
  background: var(--bg-primary);
  border-color: var(--bg-accent);
  border-top-left-radius: var(--radius-md);
  border-top-right-radius: var(--radius-md);
}

.editor :global(.ql-container) {
  background: var(--bg-primary);
  border-color: var(--bg-accent);
  border-bottom-left-radius: var(--radius-md);
  border-bottom-right-radius: var(--radius-md);
  min-height: 500px;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: var(--radius-md);
  font-weight: 500;
  transition: all var(--transition-normal);
  cursor: pointer;
  border: none;
  font-size: 1rem;
}

.primaryButton {
  padding: 0.75rem 1.5rem;
  border-radius: var(--radius-md);
  font-weight: 500;
  transition: all var(--transition-normal);
  cursor: pointer;
  border: none;
  font-size: 1rem;
  background: var(--accent-primary);
  color: var(--text-primary);
}

.primaryButton:hover {
  background: var(--accent-secondary);
  transform: translateY(-1px);
}

.secondaryButton {
  padding: 0.75rem 1.5rem;
  border-radius: var(--radius-md);
  font-weight: 500;
  transition: all var(--transition-normal);
  cursor: pointer;
  border: none;
  font-size: 1rem;
  background: var(--bg-accent);
  color: var(--text-secondary);
}

.secondaryButton:hover {
  background: var(--bg-primary);
  color: var(--text-primary);
}

.loading {
  position: relative;
  pointer-events: none;
  opacity: 0.7;
}

.loading::after {
  content: '';
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  border: 2px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to { transform: translateY(-50%) rotate(360deg); }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
    margin: 1rem;
  }
  
  .header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
  
  .controls {
    width: 100%;
    justify-content: space-between;
  }
  
  .buttonGroup {
    flex-direction: column;
  }
  
  .button {
    width: 100%;
  }
}
